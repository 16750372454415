import { CircularProgress, Stack } from "@mui/material";

export default ({fertilizers, skip, setSkip, limit, total, isLoading}) => {
    if(isLoading) {
        return (
            <Stack alignItems={"center"} sx={{m: 5}}>
                <CircularProgress />
            </Stack>
        )
    }
    return (
        <div>
            <div className="container">
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Nitrogen (N)</th>
                            <th>Phosphorus (P)</th>
                            <th>Potassium (K)</th>
                            <th>Source</th>
                        </tr>
                        {fertilizers.map((fert) => {
                            return (
                                <tr key={fert.name}>
                                    <td>{fert.name}</td>
                                    <td>{Math.round(fert.nitrogen * 100) / 100}</td>
                                    <td>{Math.round(fert.phosphorus * 100) / 100}</td>
                                    <td>{Math.round(fert.potassium * 100) / 100}</td>
                                    <td><a href={fert.source.url} target="__blank">{fert.source.name}</a></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="container d-flex justify-content-center">Results {skip + 1} - {Math.min(skip + Number(limit) + 1, total)} out of {total}</div>
            <div className="container d-flex justify-content-center">
                
                <button className="btn btn-success m-1 w-50" onClick={() => {
                    let newSkip = skip - limit;
                    if(newSkip < 0) {
                        newSkip = 0;
                    }
                    setSkip(newSkip);
                }}>Previous Page</button>

                <button className="btn btn-success m-1 w-50" onClick={() => {
                    let newSkip = skip + Number(limit);
                    if(newSkip < total) {
                        setSkip(newSkip);
                    }
                }}>Next Page</button>
            </div>
        </div>
        
    )
}