import Logo from "../../images/logo_200x200.png";
import config from "../../config.json";

export default function() {
    return (
        <header>
            <br></br>
            <div className="container text-center">
                <a href={config.websiteUrl[process.env.NODE_ENV]} target="_blank" rel="noopener">
                    <img src={Logo}></img>
                </a>
                
                <h1>NPK of Everything</h1>
                
            </div>
            <hr></hr>
        </header>
    )
}