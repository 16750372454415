import { useState, useEffect } from "react";
import config from "../../config";
import FertilizerTable from "./FertilizerTable";


const baseUrl = config.dbUrl[process.env.NODE_ENV]

async function fetchCache(url) {
    let cachedResp = localStorage.getItem(url);
    //console.log(url, cachedResp);
    if(cachedResp == null) {
        let resp = await (await fetch(url)).json();
        localStorage.setItem(url, JSON.stringify(resp));
        return resp;
    }
    else {
        return JSON.parse(cachedResp);
    }
}

async function getFertilizers(sources, search="", sort="name", direction="asc", skip=0, limit=10, nmin=0, nmax=100, pmin=0, pmax=100, kmin=0, kmax=100) {

    return (await fetchCache(`${baseUrl}/v1/fertilizers?${sources.length == 0 ? "" : `sources=${sources.filter(src => src.selected).map(src => src._id).join(",")}&`}sort=${sort}&direction=${direction}&skip=${skip}&limit=${limit}${search != "" ? `&search=${search}`: ""}&nmin=${nmin}&nmax=${nmax}&pmin=${pmin}&pmax=${pmax}&kmin=${kmin}&kmax=${kmax}`)).map(fert => {
        if(sources.length > 0) {
            fert.source = sources.find(src => src._id == fert.source);
        }
        
        return fert;
    });
}

async function getTotal(sources, search="", nmin=0, nmax=100, pmin=0, pmax=100, kmin=0, kmax=100) {
    return (await fetchCache(`${baseUrl}/v1/fertilizers/total?${sources.length == 0 ? "" : `sources=${sources.filter(src => src.selected).map(src => src._id).join(",")}&`}${search != "" ? `search=${search}&`: ""}nmin=${nmin}&nmax=${nmax}&pmin=${pmin}&pmax=${pmax}&kmin=${kmin}&kmax=${kmax}`)).total;
    
}

function getSources() {
    return fetchCache(`${baseUrl}/v1/sources`);
}


export default function Table() {
    const [fertilizers, setFertilizers] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("nameLength");
    const [direction, setDirection] = useState("asc");
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);

    const [nmin, setNmin] = useState(0);
    const [nmax, setNmax] = useState(100);

    const [pmin, setPmin] = useState(0);
    const [pmax, setPmax] = useState(100);

    const [kmin, setKmin] = useState(0);
    const [kmax, setKmax] = useState(100);

    const [sources, setSources] = useState([]);

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => getSources().then(srcs => setSources(srcs.map(src => {
        src.selected = true;
        return src;
    }))), []);

    useEffect(() => {
        setIsLoading(true)
        getFertilizers(sources, search, sort, direction, skip, limit, nmin, nmax, pmin, pmax, kmin, kmax).then((fert) => {
            setFertilizers(fert);
            setIsLoading(false)
            
        });
        
    }, [sources, search, sort, direction, skip, limit, nmin, nmax, pmin, pmax, kmin, kmax]);

    useEffect(() => {
        getTotal(sources, search, nmin, nmax, pmin, pmax, kmin, kmax).then((total) => {
            setTotal(total);
            setSkip(0);
        })
    }, [sources, search, nmin, nmax, pmin, pmax, kmin, kmax, sources]);

    return (
        
        <div className="container p-5">
            
            <div className="container">
                    
                <div className="form-group">
                    <label htmlFor="search">Search</label>
                    <input id="search" type="text" placeholder="Search" className="form-control" onChange={(event) => {
                        setSearch(event.target.value);
                    }}></input>
                </div>


                <div className="form-group">
                    <label htmlFor="sort">Sort</label>
                    <select id="sort" className="form-control" onChange={(event) => {
                        setSort(event.target.value)
                    }}>
                        <option value="nameLength">Name Length</option>
                        <option value="name">Name</option>
                        <option value="nitrogen">Nitrogen (N)</option>
                        <option value="phosphorus">Phosphorus (P)</option>
                        <option value="potassium">Potassium (K)</option>
                        <option value="source">Source</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="direction">Direction</label>
                    <select id="direction" className="form-control" onChange={(event) => {
                        setDirection(event.target.value);
                    }}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>  
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="limit">Results per Page</label>
                    <input id="limit" type="Number" className="form-control" onChange={(event) => {
                        if(event.target.value > 0) {
                            setLimit(event.target.value);
                        }
                    }} value={limit}></input>
                </div>


                <div className="row">
                    <div className="col">
                        <label htmlFor="nitrogenRange">Nitrogen</label>
                        <div id="nitrogenRange" className="row">
                            <div className="col">
                                <input type="Number" className="form-control" value={Math.round(nmin * 100) / 100} onChange={event => {
                                    setNmin(event.target.value);
                                }}></input>
                            </div>
                            to
                            <div className="col">
                                <input type="Number" className="form-control" value={Math.round(nmax * 100) / 100} onChange={event => {
                                    setNmax(event.target.value);
                                }}></input>
                            </div>
                        </div>

                        <label htmlFor="phosphorusRange">Phosphorus</label>
                        <div id="phosphorusRange" className="row">
                            <div className="col">
                                <input type="Number" className="form-control" value={Math.round(pmin * 100) / 100} onChange={event => {
                                    setPmin(event.target.value);
                                }}></input>
                            </div>
                            to
                            <div className="col">
                                <input type="Number" className="form-control" value={Math.round(pmax * 100) / 100} onChange={event => {
                                    setPmax(event.target.value);
                                }}></input>
                            </div>
                        </div>

                        <label htmlFor="potassiumRange">Potassium</label>
                        <div id="potassiumRange" className="row">
                            <div className="col">
                                <input type="Number" className="form-control" value={Math.round(kmin * 100) / 100} onChange={event => {
                                    setKmin(event.target.value);
                                }}></input>
                            </div>
                            to
                            <div className="col">
                                <input type="Number" className="form-control" value={Math.round(kmax * 100) / 100} onChange={event => {
                                    setKmax(event.target.value);
                                }}></input>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <br></br>
                        <h3>Sources</h3>
                        
                        {sources.map(src => (
                            <div className="form-check" key={src._id}>
                                <input id={src._id} type="checkbox" className="form-check-input" defaultChecked={true} onChange={(event) => {
                                    setSources(sources.map(s => {
                                        if(s._id == src._id) {
                                            s.selected = event.target.checked;
                                        }
                                        return s;
                                    }));
                                }}></input>
                                <label htmlFor={src._id} className="form-check-label"><a href={src.url} target="_blank">{src.name}</a></label>
                            </div>
                        ))}
                    </div>
                </div>

                
                
                
            </div>
            <FertilizerTable 
                fertilizers={fertilizers}
                skip={skip}
                setSkip={setSkip}
                limit={limit}
                total={total}
                isLoading={isLoading}
            />
            
        </div>  
    )
}