{
    "dbUrl": {
        "development": "http://localhost:3000",
        "production": "https://api.npkofeverything.com"
    },
    "recaptcha": {
        "siteKey": "6LdxV3MdAAAAAGD-W3zpGr8gmrIoGpvepcRcZsxo",
        "secretKey": "6LdxV3MdAAAAAPQas0zq747zUYt09iCexspdfugd"
    },
    "ga": {
        "id": "G-DJS51EKHV0"
    },
    "websiteUrl": {
        "development": "http://localhost:3002",
        "production": "https://www.npkofeverything.com"
    }
}