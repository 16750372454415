import Table from "./components/Table";
import Header from "./components/Header";
import config from "../config";
import React from "react";
import ReactGA from "react-ga4";


ReactGA.initialize(config.ga.id);
ReactGA.send("pageview");

export function App() {

    


    return (
        <div className="container">
            <Header></Header>
            <Table></Table>
        </div>
    );
}